import React, { useState, useEffect } from "react";
import "../style/NavBar.css";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for burger menu toggle

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setActiveSection(id); // Update active section when clicked
    }
  };

  return (
    <div className="containerNavBar">
      <div className={`Main_Container ${isScrolled ? "scrolled" : ""}`}>
        <div className="Links">
          <button
            onClick={() => scrollToSection("home")}
            className={`Links ${activeSection === "home" ? "active" : ""}`}
          >
            Baptiste Keunebroek
          </button>
        </div>

        <div className="burger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </div>

        {/* Menu Links */}
        <div className={`Main_elements ${isMenuOpen ? "open" : ""}`}>
          <div className="Links2">
            <button
              onClick={() => scrollToSection("aboutme")}
              className={`Links2 ${
                activeSection === "aboutme" ? "active" : ""
              }`}
            >
              About me
            </button>
          </div>

          <div className="Links2">
            <button
              onClick={() => scrollToSection("projects")}
              className={`Links2 ${
                activeSection === "projects" ? "active" : ""
              }`}
            >
              My projects
            </button>
          </div>

          <div className="Links2">
            <a href="mailto:baptiste.keunebroek@gmail.com">
              <button type="button" className="buttonWelcome">
                Contact me
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
