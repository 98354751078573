import React from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";
import "../style/Home.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ProfileImage from "../assets/Baptiste_Keunebroek_Banner.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LibrairieImage from "../assets/Baptiste_Keunebroek_librairie.PNG";
import TaskManagerImage from "../assets/Baptiste_Keunebroek_Task_Manager.png";
import UnoImage from "../assets/Baptiste_Keunebroek_Uno-logo.png";
import BlindTestImage from "../assets/Baptiste_Keunebroek_blindtest_logo.jpeg";
import SafeVoteImage from "../assets/Baptiste_Keunebroek_safe_vote_logo.png";
import BkBlogImage from "../assets/Baptiste_Keunebroek_BK_Blog_logo.png";

import { SiMalt, SiLinkedin } from "react-icons/si";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [, setScreenWidth] = useState(window.innerWidth);
  const [, setActiveSection] = useState("home");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setActiveSection(id); // Update active section when clicked
    }
  };

  const projects = [
    {
      title: "Freelance Developer",
      description: "Designed and deployed fully functional websites...",
      className: "librairie",
      image: LibrairieImage,
      link: "https://librairie-saint-nicolas.com/",
    },
    {
      title: "Task Manager",
      description: "A Task Manager app using Angular...",
      className: "Project_Task_Manager",
      image: TaskManagerImage,
      link: "https://github.com/Baptistekeunbroek/task-tracker/",
    },
    {
      title: "UNO Project",
      description:
        "Me and my friends made a remake of the famous game, using JavaScript and Node.js",
      className: "Project_UNO",
      image: UnoImage,

      link: "https://github.com/VengaGames/Uno",
    },
    {
      title: "BlindTest",
      description:
        "Here is a school project, an online BlindTest that allows you to play with your friends! Made with React, Tailwind, Node.js, and Socket.io.",
      className: "Project_BlindTest",
      image: BlindTestImage,

      link: "https://github.com/Malo-LC/BlindTest",
    },
    {
      title: "Safe Vote",
      description:
        "A website allowing people to vote online for upcoming elections.",
      className: "Project_Safe_Vote",
      image: SafeVoteImage,

      link: "https://github.com/Baptistekeunbroek/SafeVote",
    },
    {
      title: "BK Blog",
      description:
        "A Go-based blogging platform that allows users to register, log in, and create posts.",
      className: "Project_BK_Blog",
      image: BkBlogImage,

      link: "https://github.com/Baptistekeunbroek/go_blog",
    },
  ];

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="portofolio">
      <section id="home" className="HomeSection">
        <div className="HomePage">
          <div className="ProfileImage">
            <motion.img
              src={ProfileImage}
              alt="Baptiste Keunebroek"
              initial={{ opacity: 0 }} // Start off-screen (or slightly below)
              animate={{ opacity: 1 }} // Fade in and move to its normal position
              transition={{ duration: 1, ease: "easeOut" }}
              whileInView={{ opacity: 1, y: 0 }} // Trigger when in view
              viewport={{ once: true, amount: 0.2 }} // Trigger animation when 20% of the image is visible
              loading="lazy"
            />

            <motion.div
              className="ProfilePresentation"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <motion.h1
                className="ProfileText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.4 }}
              >
                Baptiste Keunebroek
              </motion.h1>
              <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
              >
                Software Engineer - Web Developer
              </motion.h2>
              <motion.h3
                className="ProfileText"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
              >
                <button
                  className="ProfileButton"
                  onClick={() => scrollToSection("aboutme")}
                >
                  About me
                </button>
              </motion.h3>
              <motion.h3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
              >
                <div className="social_icons">
                  <a
                    href="https://www.linkedin.com/in/baptiste-keunebroek-95a2ab194/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ProfileIconLink"
                  >
                    <SiLinkedin size={30} className="ProfileIcon" />
                  </a>
                  <a
                    href="https://www.malt.fr/profile/baptistekeunebroek"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ProfileIconLink"
                  >
                    <SiMalt size={50} className="ProfileIcon" />
                  </a>
                </div>
              </motion.h3>
            </motion.div>
          </div>
        </div>
      </section>
      <section id="aboutme" className="AboutSection">
        <motion.div
          className="aboutme"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <div className="container_Top_about">
            <div className="container_presentation">
              <motion.h1
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                Hello,
              </motion.h1>
              <motion.h1
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.1 }}
                viewport={{ once: true }}
              >
                I'm Baptiste !
              </motion.h1>
              <br />
              <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.3 }}
                viewport={{ once: true }}
              >
                I'm a recently graduated engineer in computer science with a
                Master in Software Development. I'm passionate about web
                development and I'm currently working as a freelance developer.
              </motion.p>
              <a href="https://www.linkedin.com/in/baptiste-keunebroek-95a2ab194/">
                <motion.button
                  type="button"
                  className="contact_linkedin"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  viewport={{ once: true }}
                >
                  linkedin.com/in/baptiste-keunebroek
                </motion.button>
              </a>
            </div>
          </div>

          <div className="container_Middle_about">
            <div className="experience">
              <motion.h1
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                Experience
              </motion.h1>
              <motion.ul
                className="odlaMer"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.3 }}
                viewport={{ once: true }}
              >
                <li>Current : Freelance Developer</li>
                <p>Specialized in creating modern and responsive websites.</p>
              </motion.ul>

              <motion.ul
                className="odlaMer"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
              >
                <li>2024 : Full Stack Developer at Marimetrics</li>
                <p>
                  Software engineer responsible for architecting, developing and
                  verifying software applications for Marimetrics products.
                </p>
              </motion.ul>

              <motion.ul
                className="odlaMer"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.7 }}
                viewport={{ once: true }}
              >
                <li>2022-2023 : React Native Developer at Odlamer</li>
                <p>
                  Built a gardening community and smart tools mobile app using
                  React Native.
                </p>
              </motion.ul>
            </div>

            <motion.div
              className="education"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              viewport={{ once: true }}
            >
              <motion.h1
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
              >
                Education
              </motion.h1>
              <motion.ul
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
              >
                <li>2019-2024 : MS - Computer Science, EFREI Paris</li>
                <p>
                  Specialization in software engineering with a focus on web
                  technologies.
                </p>
              </motion.ul>
              <motion.ul
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
              >
                <li>
                  2017-2019 : BSc - Information Technology, Université de Paris
                </li>
                <p>Foundation studies in programming and software design.</p>
              </motion.ul>
            </motion.div>
          </div>
        </motion.div>
      </section>
      <section id="projects" className="HomeSection">
        <div className="projects_page">
          <div className="projects-title">
            <h1>My Portfolio</h1>
          </div>

          <Slider {...settings}>
            {projects.map((project) => (
              <div className="project_slide" key={project.id}>
                <NavLink
                  to={project.link}
                  target="_blank"
                  className="project-link"
                >
                  <img
                    src={project.image}
                    alt={project.title}
                    className="project-image"
                    loading="lazy"
                  />
                </NavLink>
                <div className="project-content">
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default Home;
